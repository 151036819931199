import React, { FC, PropsWithChildren, useRef, useState } from 'react';
import { Box } from '@material-ui/core';
import fallbackImageAPIPlatform from '../../../../../../assets/home/fallback-image-api-platform.jpg';
import { useStyles } from './styles';

export interface VideoBackgroundProps {
  videoSourceName: string;
}

export const VideoBackground: FC<PropsWithChildren<VideoBackgroundProps>> = ({ videoSourceName, children }) => {
  const classes = useStyles();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isVideoError, setIsVideoError] = useState(false);

  return (
    <Box className={classes.background}>
      {isVideoError ? (
        <picture>
          <source srcSet="home/fallback-image-api-platform.webp" type="image/webp" />
          <img
            src={fallbackImageAPIPlatform}
            alt="Fallback image for Swiss Re Developer Portal background"
            className={classes.media}
            {...{ fetchpriority: 'high' }}
          />
        </picture>
      ) : (
        <video ref={videoRef} autoPlay loop muted className={classes.media} onError={() => setIsVideoError(true)}>
          <source src={`/${videoSourceName}`} type="video/mp4" />
          <track kind="captions" srcLang="en" label="English" />
        </video>
      )}
      {children}
    </Box>
  );
};
