import apiAnalyticsFeature from '../../../../assets/dev-resources/APIAnalytics_APIsSwissReDeveloperPortal.jpg';
import seamlessSecureDataIntegration from '../../../../assets/dev-resources/SeamlessSecureDataIntegration_APIsSwissReDeveloperPortal.jpg';
import demoSandboxEnvironment from '../../../../assets/dev-resources/DemoSandboxAPIS_APIsSwissReDeveloperPortal.jpg';

export interface Resource {
  id: string;
  title: string;
  subTitle: string;
  image: string;
  altText: string;
  webpImage: string;
}

export const RESOURCES: ReadonlyArray<Resource> = [
  {
    id: 'seamless-data-integration',
    title: 'Seamless & Secure Data Integration',
    subTitle: 'Use our comprehensive guides to securely connect with our APIs.',
    image: seamlessSecureDataIntegration,
    altText: 'Code example of an API request call for the CatNet API (API assessing global catastrophic risks).',
    webpImage: 'dev-resources/SeamlessSecureDataIntegration_APIsSwissReDeveloperPortal.webp'
  },
  {
    id: 'demo-sandbox-environment',
    title: 'Demo & Sandbox Environment',
    subTitle: 'Download specifications and utilise samples to connect in minutes.',
    image: demoSandboxEnvironment,
    altText: 'API information and response sample of an API call.',
    webpImage: 'dev-resources/DemoSandboxAPIS_APIsSwissReDeveloperPortal.webp'
  },
  {
    id: 'api-analytics',
    title: 'API Analytics',
    subTitle: 'Track API activity and consumption at any time. No surprises.',
    image: apiAnalyticsFeature,
    altText: 'Illustration of the API reporting functionality within the Swiss Re Developer Portal.',
    webpImage: 'dev-resources/APIAnalytics_APIsSwissReDeveloperPortal.webp'
  },
];
