import catnet from '../assets/apis/catnet.jpg';
import rds from '../assets/apis/risk-data-services.jpg';
import coiin from '../assets/apis/commercial-insurance-insights.jpg';
import ncme from '../assets/apis/nat-cat.jpg';
import pdsUs from '../assets/apis/property-data-services.jpg';
import natcatParametric from '../assets/apis/ncme.jpg';

export interface API {
  id: string;
  name: string;
  description: string;
  image: string;
  webpImage: string
  mobileImage?: string
}

export const APIS: ReadonlyArray<API> = [
  {
    id: 'catnet',
    name: 'CatNet® API',
    description:
      "Integrate Swiss Re's property risk expertise into underwriting and pricing systems, with proprietary risk maps and consistent hazard scores.",
    image: catnet,
    webpImage: "apis/catnet-API-desktop.webp"
  },
  {
    id: 'rds',
    name: 'Risk, Data & Services for Corporates',
    description: 'Take control by connecting into relevant property, climate and supply chain risk insights.',
    image: rds,
    webpImage: "apis/risk-data-services-API-desktop.webp"
  },
  {
    id: 'coiin',
    name: 'Commercial Insurance Insights',
    description: 'Integrate risk and sustainability assessments to identify growth opportunities in the commercial insurance market.',
    image: coiin,
    webpImage: "apis/commercial-insurance-insights-API-desktop.webp",
    mobileImage: "apis/commercial-insurance-insights-API-mobile-img.webp",
  },
  {
    id: 'ncme',
    name: 'NatCat Modelling Engine (NCME)',
    description: 'Evolve your risk selection, pricing and portfolio management with cutting-edge natural catastrophe models.',
    image: ncme,
    webpImage: 'apis/ncme-API-desktop.webp',
    mobileImage: 'apis/ncme-API-mobile-img.webp'
  },
  {
    id: 'pds-us',
    name: 'Property Data Service US',
    description:
      'A curated dataset providing core property attributes and risk insights for any property in the US for risk selection, underwriting and rating.',
    image: pdsUs,
    webpImage: "apis/property-data-services-API-desktop.webp",
    mobileImage: "apis/property-data-services-API-mobile-img.webp"
  },
  {
    id: 'natcat-parametric',
    name: 'NatCat Parametric',
    description:
      "Integrate Swiss Re's parametric Natural Catastrophe modules for pricing, underwriting, and claim auto-tracking into your systems.",
    image: natcatParametric,
    webpImage: 'apis/nat-cat-API-desktop.webp',
    mobileImage: 'apis/nat-cat-API-mobile-img.webp'
  },
];
