import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Box, Link, useMediaQuery, useTheme } from '@material-ui/core';
import { Routes } from '../../constants/routes';
import { useAuth } from '../../hooks';
import Logo from '../../components/Logo';
import { SignUpDialog } from '../../components/SignUp/SignUpDialog';
import { SignIn } from '../../components/SignIn';
import { useStyles } from './styles';

export const Header: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { user } = useAuth();

  return (
    <header className={classes.header}>
      <Box className={classes.leftItemsBox}>
        <Link className={classes.logo} to={Routes.HOME} component={RouterLink} aria-label='SwissRe logo'>
          <Logo />
          {matches && <Typography className={classes.logoTitle}>Developer Portal</Typography>}
        </Link>
        {user && (
          <>
            <Link className={classes.navLink} to={Routes.SOLUTIONS} component={RouterLink}>
              Solutions
            </Link>
            <Link className={classes.navLink} to={Routes.SUBSCRIPTIONS} component={RouterLink}>
              My Subscriptions
            </Link>
            <Link className={classes.navLink} to={Routes.API_ANALYTICS} component={RouterLink}>
              API Analytics
            </Link>
            <Link className={classes.navLink} to={Routes.GUIDES} component={RouterLink}>
              Guides
            </Link>
          </>
        )}
      </Box>
      <Box className={classes.rightItemsBox}>
        <SignIn />
      </Box>
      <SignUpDialog />
    </header>
  );
};
