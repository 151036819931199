import { Theme, alpha, makeStyles } from '@material-ui/core';
import image from '../../../../assets/home/explore-further-swissre-developer-portal.jpg';

//The WebP format is supported by Backstage by default only for images located in the public folder
const imageDesktop = 'home/explore-further-swissre-developer-portal-desktop.webp';
const imageMobile = 'home/explore-further-swissre-developer-portal-mobile.webp';

export const useStyles = makeStyles<Theme>((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 700,
    textAlign: 'center',
    color: theme.palette.primary.contrastText,
    backgroundImage: `url(${image})`,// Fallback for older browsers
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    marginTop: 48,

    '@supports (background-image: url(`${imageDesktop}`))': {
      backgroundImage: `url(${imageDesktop})`,
    },

    [theme.breakpoints.down('sm')]: {
      height: 540,
      backgroundImage: `url(${image})`, // Fallback image for mobile
      '@supports (background-image: url(`${imageMobile}`))': {
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2)), url(${imageMobile})`,
      },
    },
    backgroundColor: `${alpha(theme.palette.grey[600], 0.4)}`,
  },
  link: {
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },

    '&:hover': {
      color: theme.palette.grey[100],
    },
  },
  button: {
    borderColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.contrastText,
    backgroundColor: 'transparent',
    marginTop: theme.spacing(3),
    '&:hover': {
      backgroundColor: `${alpha(theme.palette.primary.contrastText, 0.2)}`,
      borderColor: 'transparent',
    },
  },
}));
